
/* @tailwind base;
@tailwind components;
@tailwind utilities; */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: contain;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input[type="number"] {
  -webkit-appearance: textfield;
  appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  /* Additional styles to ensure consistent appearance across browsers */
}
.sun-editor {
  word-break: break-word; /* Apply the word-break property */
}

