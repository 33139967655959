.hoverBtn:hover {
  background: #dfe0e1;
}
.hoverBtn {
  border: 1px solid #525e7e;
}
.hoverBtn {
  color: #525e7e;
}
.backHomeBtn:hover {
  background: #dfe0e1;
}
.backHomeBtn {
  color: #525e7e;
  border: 1px solid #525e7e;
  margin-right: 3rem;
}
.stat-item {
  width: 350px;
}
.simulation-card {
  width: 100%;
  border-radius: 4px;
  font-family: "Nunito";
  border: 1px solid #707070;
  transition: all 0.3s;
  border-radius: 15px;
}
.top-simulation-card {
  width: 100%;
  border-radius: 4px;
  font-family: "Nunito";
  border: 1px solid #707070;
  transition: all 0.3s;
}
.modalPlay2 {
  background: linear-gradient(#3174f3, #1b4cac);
  display: none;
}
.modalPlay2:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(180deg, #3174f3 0%, #1b4cac 100%);
}
.modalPlay {
  background: linear-gradient(#3174f3, #1b4cac);
  display: flex;
}
.modalPlay:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(180deg, #3174f3 0%, #1b4cac 100%);
}
.simulation-card:hover {
  box-shadow: 0px 4px 16px 0px #00000040;
}
.simulation-card > img {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.top-simulation-card:hover {
  box-shadow: 0px 4px 16px 0px #00000040;
}
.top-simulation-card > img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.header-simu {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}
.header-container {
  justify-content: flex-start;
  gap: 2rem;
  align-items: center;
  font-family: "Nunito";
}
.simulations-heading {
  font-size: 40px;
  font-weight: 700;
}
.backIcon {
  display: none;
}
.feature-container {
  position: fixed;
  top: 2rem;
  left: 30px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 0;
  background-color: white;
  z-index: 2;
}
@media (max-width: 428px) {
  .vignam-name {
    display: none;
  }
  .simu-tag {
    font-size: 11px !important;
  }
  .modalPlay {
    display: none;
  }
  .modalPlay2 {
    display: flex;
  }
  .backHomeBtn {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 38px;
    height: 38px;
    border: 2px solid gray;
  }
  .backIcon {
    display: inline-block;
  }
  .back-name {
    display: none;
  }
  .type-simulation {
    text-align: left !important;
    margin: 15px;
  }
  .backHomeBtn {
    margin-right: -20px;
  }
  .header-simu {
    flex-direction: row-reverse;
  }
  .container {
    padding: 0 !important;
  }
}

.type-simulation {
  margin-top: 7.5rem !important;
}
@media (max-width: 1140px) {
  .simu-tag {
    font-size: 10px !important;
  }
}

@media (max-width: 768px) {
  .feature-container {
    flex-direction: column;
    gap: 0 !important;
    left: 0;
  }
  .type-simulation {
    margin-top: 12rem !important;
  }
  .search-container {
    width: 100%;
  }
  .cards-container {
    margin-left: 1;
  }
  .feature-container {
    margin-top: 1rem;
  }
  .type-simulation {
    text-align: center;
    margin: 15px;
  }
  .search-bar {
    width: 100% !important;
  }
  .simulations-heading {
    font-size: 32px;

    font-weight: 500;
  }
  .header-container {
    /* flex-direction: column !important; */
    justify-content: space-between !important;
    padding-left: 10px;
    padding-right: 10px;
    gap: 10px !important;
  }
}
